$(document).ready(function () {
  ("use strict");

  // ----
  // Menú
  // ----

  // jQuery element variables
  var $hamburgerMenuBtn,
  $slideNav,
  $closeBtn,
  $main;

  // focus management variables
  var $focusableInNav,
  $firstFocusableElement,
  $lastFocusableElement;

  $hamburgerMenuBtn = $("#hamburger-menu"),
  $slideNav = $("#slide-nav"),
  $closeBtn = $("#close"),
  $main = $("main"),
  $focusableInNav = $('#slide-nav button, #slide-nav [href], #slide-nav input, #slide-nav select, #slide-nav textarea, #slide-nav [tabindex]:not([tabindex="-1"])');
  if ($focusableInNav.length) {
    $firstFocusableElement = $focusableInNav.first();
    $lastFocusableElement = $focusableInNav.last();
  }
  addEventListeners();

  function addEventListeners() {
    $hamburgerMenuBtn.click(openNav);
    $closeBtn.click(closeNav);
    $slideNav.on("keyup", closeNav);
    $firstFocusableElement.on("keydown", moveFocusToBottom);
    $lastFocusableElement.on("keydown", moveFocusToTop);
  }

  function openNav() {
    $slideNav.addClass("visible active");
    setTimeout(function() {
      $firstFocusableElement.focus()
    }, 1);
    $main.attr("aria-hidden", "true");
    $hamburgerMenuBtn.attr("aria-hidden", "true");
  }

  function closeNav(e) {
    if (e.type === "keyup" && e.key !== "Escape") {
      return;
    }

    $slideNav.removeClass("active");
    $main.removeAttr("aria-hidden");
    $hamburgerMenuBtn.removeAttr("aria-hidden");
    setTimeout(function() {
      $hamburgerMenuBtn.focus()
    }, 1);
    setTimeout(function() {
      $slideNav.removeClass("visible")
    }, 250);
  }

  function moveFocusToTop(e) {
    if (e.key === "Tab" && !e.shiftKey) {
      e.preventDefault();
      $firstFocusableElement.focus();
    }
  }

  function moveFocusToBottom(e) {
    if (e.key === "Tab" && e.shiftKey) {
      e.preventDefault();
      $lastFocusableElement.focus()
    }
  }

  // Menú: afegim botó i ocultem menú quan hi ha js
  // $("#menu")
  //   .before(
  //     '<button class="hamburger hamburger--spring pr-0 pt-0" type="button" aria-expanded="false" aria-controls="menu"><span class="hamburger-box"><span class="hamburger-inner"></span></span><span class="is-sr-only">Menu</span></button>'
  //   )
  //   .attr("hidden", "true");

  // ----
  // menú (https://polypane.rocks/blog/the-perfect-responsive-menu-2019/)
  // ----
  // var toggleMenu = document.querySelector(".navigation button");
  // var menu = document.querySelector("#menu");

  // if (toggleMenu) {
  //   toggleMenu.addEventListener("click", function () {
  //     var open = JSON.parse(toggleMenu.getAttribute("aria-expanded"));
  //     toggleMenu.setAttribute("aria-expanded", !open);
  //     menu.hidden = !menu.hidden;
  //   });
  // }

  // $(".hamburger").on("click", function () {
  //   // afegir classe al botó
  //   $(this).toggleClass("is-active");
  //   // afegir classe al body
  //   $("body").toggleClass("js-menu-open");
  // });

  // -------------------------------
  // Menú principal amb desplegables (https://www.w3.org/WAI/tutorials/menus/flyout/#use-button-as-toggle)
  // -------------------------------

  // var menuItems = document.querySelectorAll("#menu li.has-submenu");
  // Array.prototype.forEach.call(menuItems, function (el, i) {
  //   var activatingA = el.querySelector("a");
  //   var btn =
  //     '<button><span><span class="is-sr-only">Mostra el submenú per “' +
  //     activatingA.text +
  //     "”</span></span></button>";
  //   activatingA.insertAdjacentHTML("afterend", btn);
  //   // activatingA.setAttribute("aria-expanded", "false");

  //   el.querySelector("button").addEventListener("click", function (event) {
  //     var currentMenu = this.parentNode;
  //     Array.prototype.forEach.call(menuItems, function (el, i) {
  //       if (currentMenu !== el) {
  //         el.classList.remove("open");
  //         el.classList.add("close");
  //         //el.querySelector("a").setAttribute("aria-expanded", "false");
  //         el.querySelector("button").setAttribute("aria-expanded", "false");
  //       }
  //     });
  //     //console.log(this.parentNode.className);
  //     if (this.parentNode.classList.contains("close")) {
  //       this.parentNode.classList.add("open");
  //       this.parentNode.classList.remove("close");
  //       /*this.parentNode
  //         .querySelector("a")
  //         .setAttribute("aria-expanded", "true");*/
  //       this.parentNode
  //         .querySelector("button")
  //         .setAttribute("aria-expanded", "true");
  //     } else {
  //       this.parentNode.classList.add("close");
  //       this.parentNode.classList.remove("open");
  //       /*this.parentNode
  //         .querySelector("a")
  //         .setAttribute("aria-expanded", "false");*/
  //       this.parentNode
  //         .querySelector("button")
  //         .setAttribute("aria-expanded", "false");
  //     }
  //     event.preventDefault();
  //   });
  // });

  // ---------
  // skip link (https://www.bignerdranch.com/blog/web-accessibility-skip-navigation-links/)
  // ---------

  // bind a click event to the 'skip' link
  $(".skip-link").click(function (event) {
    // strip the leading hash and declare
    // the content we're skipping to
    var skipTo = "#" + this.href.split("#")[1];

    // Setting 'tabindex' to -1 takes an element out of normal
    // tab flow but allows it to be focused via javascript
    $(skipTo)
      .attr("tabindex", -1)
      .on("blur focusout", function () {
        // when focus leaves this element,
        // remove the tabindex attribute
        $(this).removeAttr("tabindex");
      })
      .focus(); // focus on the content container
  });

  // ----------
  // scroll top
  // ----------
  // $(window).on('scroll', function () {
  //   if ($(this).scrollTop() > 400) {
  //     $(".scrolltop").addClass("visible");
  //   } else {
  //     $(".scrolltop").removeClass("visible");
  //   }
  // });

  // Function to check if the footer is in the viewport
  // function isFooterInViewport() {
  //   var windowHeight = $(window).height();
  //   var footerTop = $('footer div.has-background-black').offset().top;

  //   return footerTop < (windowHeight + $(window).scrollTop());
  // }

  // // Show or hide scrolltop button based on scroll position
  // $(window).on('scroll', function () {
  //   if (isFooterInViewport()) {
  //     console.log("FooterInViewport");
  //     $('.scrolltop').css('position', 'static');
  //     // $('.scrolltop').addClass('mb-4');
  //   } else {
  //     console.log("no FooterInViewport");
  //     $('.scrolltop').css('position', 'fixed');
  //     // $('.scrolltop').removeClass('mb-4');
  //   }
  // });


  // ------------
  // Wrapper (per alinear el .wrapper--left / .wrapper--right)
  // ------------
  // function updateMargin() {
  //   var marginLeft = $('.wrapper').css('margin-left');
  //   $('.wrapper--left').css('margin-left', marginLeft);
  //   var marginRight = $('.wrapper').css('margin-right');
  //   $('.wrapper--right').css('margin-right', marginRight);
  // }
  // updateMargin();
  // $(window).on('resize', function () {
  //   updateMargin();
  // });

  // ---------------
  // Slider opinions
  // ---------------
  $('.slider').slick({
    // dots: true
  });

  // Get all Vimeo iframes in the slider
  var vimeoPlayers = [];

  $('.slider iframe').each(function () {
      var iframe = $(this)[0];
      var player = new Vimeo.Player(iframe);

      // Add the player to the array
      vimeoPlayers.push(player);
  });

  // Pause all videos when changing slides
  $('.slider').on('afterChange', function (event, slick, currentSlide) {
      // Pause all videos
      vimeoPlayers.forEach(function (player) {
          player.pause();
      });
  });

  // ----------------------------------------------------------
  // Invertir colors capçalera al fer scroll (home)
  // ----------------------------------------------------------
  var isHome = document.getElementsByClassName("home");
  if (isHome.length > 0) {
    $(window).on('scroll', function() {
      var distanceScrolled = $(this).scrollTop();
      if (distanceScrolled >= 50) {
        $(".main-header").removeClass("negative").addClass('has-background-white');
      } else {
        $(".main-header").addClass("negative").removeClass('has-background-white');
      }
    });
  }

  // --------
  // headroom
  // --------
  // $(".main-header").headroom(
    // {
    // offset: 0,
    // tolerance: 5,
    // classes: {
    //   initial: "animated",
    //   pinned: "slideDown",
    //   unpinned: "slideUp"
    // }
  // }
  // );

  // -----------
  // File upload
  // -----------
  // $('input[type="file"]').change(function () {
  //   var i = $(this).next().find(".file-label").clone();
  //   var file = $('input[type="file"]')[0].files[0].name;
  //   $(this).next().find(".file-label").text(file);
  // });

  // ----
  // Tabs https://inclusive-components.design/tabbed-interfaces/
  // ----
  // if ($(".tabbed").length > 0) {
  //   $(".tabbed").each(function (index, element) {
  //     // Get relevant elements and collections
  //     var tabbed = element;
  //     var tablist = tabbed.querySelector("ul.tab-menu");
  //     var tabs = tablist.querySelectorAll("a");
  //     var panels = tabbed.querySelectorAll(".tab-panel");

  //     // The tab switching function
  //     var switchTab = function switchTab(oldTab, newTab) {
  //       newTab.focus(); // Make the active tab focusable by the user (Tab key)

  //       newTab.removeAttribute("tabindex"); // Set the selected state

  //       newTab.setAttribute("aria-selected", "true");
  //       oldTab.removeAttribute("aria-selected");
  //       oldTab.setAttribute("tabindex", "-1"); // Get the indices of the new and old tabs to find the correct
  //       // tab panels to show and hide

  //       var index = Array.prototype.indexOf.call(tabs, newTab);
  //       var oldIndex = Array.prototype.indexOf.call(tabs, oldTab);
  //       panels[oldIndex].hidden = true;
  //       panels[index].hidden = false;
  //     }; // Add the tablist role to the first <ul> in the .tabbed container

  //     tablist.setAttribute("role", "tablist"); // Add semantics are remove user focusability for each tab

  //     Array.prototype.forEach.call(tabs, function (tab, i) {
  //       tab.setAttribute("role", "tab");
  //       //	    tab.setAttribute("id", "tab" + (i + 1));
  //       tab.setAttribute("tabindex", "-1");
  //       tab.parentNode.setAttribute("role", "presentation"); // Handle clicking of tabs for mouse users

  //       tab.addEventListener("click", function (e) {
  //         e.preventDefault();
  //         var currentTab = tablist.querySelector("[aria-selected]");

  //         if (e.currentTarget !== currentTab) {
  //           switchTab(currentTab, e.currentTarget);
  //         }
  //       }); // Handle keydown events for keyboard users

  //       tab.addEventListener("keydown", function (e) {
  //         // Get the index of the current tab in the tabs node list
  //         var index = Array.prototype.indexOf.call(tabs, e.currentTarget); // Work out which key the user is pressing and
  //         // Calculate the new tab's index where appropriate

  //         var dir =
  //           e.which === 37
  //             ? index - 1
  //             : e.which === 39
  //               ? index + 1
  //               : e.which === 40
  //                 ? "down"
  //                 : null;

  //         if (dir !== null) {
  //           e.preventDefault(); // If the down key is pressed, move focus to the open panel,
  //           // otherwise switch to the adjacent tab

  //           dir === "down"
  //             ? panels[i].focus()
  //             : tabs[dir]
  //               ? switchTab(e.currentTarget, tabs[dir])
  //               : void 0;
  //         }
  //       });
  //     }); // Add tab panel semantics and hide them all

  //     Array.prototype.forEach.call(panels, function (panel, i) {
  //       panel.setAttribute("role", "tabpanel");
  //       panel.setAttribute("tabindex", "-1");
  //       var id = panel.getAttribute("id");
  //       panel.setAttribute("aria-labelledby", tabs[i].id);
  //       panel.hidden = true;
  //     }); // Initially activate the first tab and reveal the first tab panel

  //     tabs[0].removeAttribute("tabindex");
  //     tabs[0].setAttribute("aria-selected", "true");
  //     panels[0].hidden = false;
  //   });
  // }

  // Animació imatges grans
  // ----------------------
  // $(".animated-img").addClass("hidden").viewportChecker({
  //   classToAdd: "visible animated-img--animated",
  //   offset: 100,
  // });

  // Animació logo Dédalo
  // --------------------
  // $(".logo-dedalo").each(function () {
  //   var element = this;
  //   $(element).addClass("hidden");
  //   $(this).viewportChecker({
  //       classToAdd: "visible",
  //       callbackFunction: function () {
  //           // Draw the path using Vivus
  //           var vivus = new Vivus(element, {
  //               duration: 100,
  //               animTimingFunction: Vivus.EASE_OUT,
  //           }, function () {
  //               // Animation completed, start the gradient animations
  //               startGradientAnimations(element);
  //           });
  //       },
  //   });

  //   // Function to start the gradient animations
  //   function startGradientAnimations(svgElement) {
  //       // Get the linear gradients within the SVG
  //       var linearGradients = $(svgElement).find("linearGradient");

  //       // Start animations for each linear gradient
  //       linearGradients.each(function (index) {
  //           var gradientElement = this;
  //           var stops = $(gradientElement).find("stop");

  //           stops.each(function () {
  //               var stopElement = this;
  //               // Start the animation for each stop
  //               $(stopElement).find("animate").get(0).beginElement();
  //           });
  //       });
  //   }
  // });

  // Vídeo portada
  // -------------
  var firstVideoElement = document.getElementById("firstVideo");
  if (firstVideoElement) {
    var firstVideoPlayer = new Vimeo.Player(document.getElementById('firstVideo'));
    // When the popup is opened, pause the first video
    $('.popup-vimeo').magnificPopup({
      // disableOn: 700,
      mainClass: 'mfp-fade',
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false,
      type: 'iframe',
      // iframe: {
      //     patterns: {
      //         vimeo: {
      //             index: 'vimeo.com/',
      //             id: '/',
      //             src: 'https://player.vimeo.com/video/%id%?autoplay=1'
      //         }
      //     }
      // },
      callbacks: {
          open: function () {
              // Pause the first video when the popup is opened
              firstVideoPlayer.pause();
          },
          close: function () {
              // Resume the first video when the popup is closed
              firstVideoPlayer.play();
          }
      }
    });
  }


});

// -----------
// Collapsible (https://inclusive-components.design/collapsible-sections/)
// -----------
(function () {
  // Get all the headings
  const headings = document.querySelectorAll('.collapsible__title')

  Array.prototype.forEach.call(headings, heading => {
    // Give each <h2> a toggle button child
    // with the SVG plus/minus icon
    heading.innerHTML = `
      <button aria-expanded="false" class="is-flex is-justify-content-space-between is-align-items-center py-5">
        <span class="is-flex is-align-items-center gap-4">${heading.innerHTML}</span>
        <svg class="is-flex-shrink-0" width="20" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M12 8H8v4h4V8ZM8 4H4v4h4V4Zm8 0h-4v4h4V4ZM4 0H0v4h4V0Zm16 0h-4v4h4V0Z" fill="#000" fill-rule="evenodd"/></svg>
      </button>
    `
    // Function to create a node list
    // of the content between this heading and the next
    const getContent = (elem) => {
      let elems = []
      while (elem.nextElementSibling && elem.nextElementSibling.className !== 'collapsible__title') {
        elems.push(elem.nextElementSibling)
        elem = elem.nextElementSibling
      }

      // Delete the old versions of the content nodes
      elems.forEach((node) => {
        node.parentNode.removeChild(node)
      })

      return elems
    }

    // Assign the contents to be expanded/collapsed (array)
    let contents = getContent(heading)

    // Create a wrapper element for `contents` and hide it
    let wrapper = document.createElement('div')
    wrapper.hidden = true

    // Add each element of `contents` to `wrapper`
    contents.forEach(node => {
      wrapper.appendChild(node)
    })

    // Add the wrapped content back into the DOM
    // after the heading
    heading.parentNode.insertBefore(wrapper, heading.nextElementSibling)

    // Assign the button
    let btn = heading.querySelector('button')

    btn.onclick = () => {
      // Cast the state as a boolean
      let expanded = btn.getAttribute('aria-expanded') === 'true' || false

      // Switch the state
      btn.setAttribute('aria-expanded', !expanded)
      // Switch the content's visibility
      wrapper.hidden = expanded
    }
  })

  if ($('.collapsible__title').length > 0 && window.location.hash.indexOf('#entry-') > -1 && $(window.location.hash).length > 0) {
    $(window.location.hash).find('button').attr('aria-expanded', true);
    $(window.location.hash).next().show();
    $('html, body').animate({
        scrollTop: $(window.location.hash).parent().offset().top
    }, 1000);
  }

})();


// -----------------
// Imatge pixel·lada
// -----------------
// if (document.getElementsByTagName('canvas').length > 0) {
//   var ctx = canvas.getContext('2d'),
//   img = new Image(),
//   play = false,
//   imgNumber = Math.floor(Math.random() * 4) + 1;

//   // turn off image smoothing - this will give the pixelated effect
//   ctx.mozImageSmoothingEnabled = false;
//   ctx.webkitImageSmoothingEnabled = false;
//   ctx.imageSmoothingEnabled = false;

//   // set the image source
//   img.src = '/media/img/foto-pixelisme-0' + imgNumber + '.jpg';

//   // Intersection Observer configuration
//   var options = {
//     threshold: 0.5 // Change this threshold value based on your needs
//   };

//   // Intersection Observer callback
//   var observer = new IntersectionObserver(function (entries, observer) {
//     entries.forEach(function (entry) {
//       if (entry.isIntersecting) {
//         // Start the animation when the canvas enters the viewport
//         toggleAnim();
//         observer.unobserve(entry.target);
//       }
//     });
//   }, options);

//   // Observe the canvas element
//   observer.observe(canvas);

//   // MAIN function
//   function pixelate(v) {
//     var size = v * 0.01,
//       // cache scaled width and height
//       w = canvas.width * size,
//       h = canvas.height * size;

//     // draw the original image to the scaled size
//     ctx.drawImage(img, 0, 0, w, h);

//     // reset smoothing for the final frame to make the image clear
//     if (v >= 100) {
//       ctx.mozImageSmoothingEnabled = true;
//       ctx.webkitImageSmoothingEnabled = true;
//       ctx.imageSmoothingEnabled = true;
//     }

//     // then draw that scaled image thumb back to fill the canvas
//     ctx.drawImage(canvas, 0, 0, w, h, 0, 0, canvas.width, canvas.height);

//     // reset smoothing for the next iteration
//     if (v >= 100) {
//       ctx.mozImageSmoothingEnabled = false;
//       ctx.webkitImageSmoothingEnabled = false;
//       ctx.imageSmoothingEnabled = false;
//     }
//   }

//   // This runs the demo animation to give an impression of
//   // performance.
//   function toggleAnim() {
//     var v = 0, // Set initial block value to 0 for no pixelation
//       dx = 1; // "speed"

//     // toggle play flag
//     play = true;

//     // animation loop
//     function anim() {
//       // increase value
//       v += dx;

//       // if at min or max, stop animation
//       if (v <= 0 || v >= 100) {
//         play = false;
//         v = (v <= 0)
//           ? 0
//           : 100; // Ensure the value is set to 0 or 100 for the final frame
//       }

//       // pixelate image with the current value
//       pixelate(v);

//       // loop
//       if (play)
//         requestAnimationFrame(anim);
//       }

//     // start the animation loop
//     anim();
//   }

//   // poly-fill for requestAnimationFrame with fallback for older
//   // browsers that do not support rAF.
//   window.requestAnimationFrame = window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || function (callback) {
//     window.setTimeout(callback, 1000 / 60);
//   };
// }